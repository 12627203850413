/**
  * This injects any component classes registered by tailwindcss plugins.
  *
  * If using `postcss-import`, use this import instead:
  *
  * @import "tailwindcss/components";
  */

/* purgecss start ignore */

@tailwind components;

/* Components */

.Hilite {
  @apply relative;

  &::before {
    @apply absolute top-1/2 bottom-1/2;
    z-index: -1;
    transform: translate(-50%, -50%);
    height: 150%;
    width: 150%;
    content: "";
    background-image: radial-gradient(
      closest-side,
      rgba(theme("colors.beige.default"), 0.35) 0%,
      rgba(theme("colors.beige.default"), 0.35) 33.333%,
      transparent 100%
    );
  }
}

.Header {
  padding-bottom: 3vh;

  @screen sm {
    padding-top: 3vh;
  }
}

/* Parallax nodes */

[data-parallax] {
  will-change: transform;
}

/* Header Gradients */

[data-gradient] {
  @apply opacity-0;
  /* transition: opacity 0.6s cubic-bezier(0.06, 0.83, 0.1, 1); */
}

[data-gradient="aqua"] {
  background-image: linear-gradient(
    to bottom,
    theme("colors.aqua.light") 50%,
    rgba(theme("colors.aqua.light"), 0)
  );

  @nest [data-theme="aqua"] & {
    @apply opacity-95;
  }
}

[data-gradient="pink"] {
  background-image: linear-gradient(
    to bottom,
    theme("colors.pink.default") 50%,
    rgba(theme("colors.pink.default"), 0)
  );

  @nest [data-theme="variety-pack"] & {
    @apply opacity-95;
  }

  @nest [data-theme="clairvoyance"] & {
    @apply opacity-95;
  }
}

[data-gradient="enchantmint"] {
  background-image: linear-gradient(
    to bottom,
    theme("colors.theme-enchantmint.bg") 50%,
    rgba(theme("colors.theme-enchantmint.bg"), 0)
  );

  @nest [data-theme="enchantmint"] & {
    @apply opacity-95;
  }
}

[data-gradient="safari"] {
  background-image: linear-gradient(
    to bottom,
    theme("colors.theme-safari.bg") 50%,
    rgba(theme("colors.theme-safari.bg"), 0)
  );

  @nest [data-theme="safari"] & {
    @apply opacity-95;
  }
 }

[data-gradient="salvation"] {
  background-image: linear-gradient(
    to bottom,
    theme("colors.theme-salvation.bg") 50%,
    rgba(theme("colors.theme-salvation.bg"), 0)
  );

  @nest [data-theme="salvation"] & {
    @apply opacity-95;
  }
}

[data-gradient="walkabout"] {
  background-image: linear-gradient(
    to bottom,
    theme("colors.theme-walkabout.bg") 50%,
    rgba(theme("colors.theme-walkabout.bg"), 0)
  );

  @nest [data-theme="walkabout"] & {
    @apply opacity-95;
  }
}

[data-gradient="lavender"] {
  background-image: linear-gradient(
    to bottom,
    theme("colors.lavender.default") 50%,
    rgba(theme("colors.lavender.default"), 0)
  );

  @nest [data-theme="lavender"] & {
    @apply opacity-95;
  }
}

.TexturedBackground {
  background-image: url("../images/bg-texture-960w.png");
  background-size: 640px;

  @screen md {
    background-size: 960px;
  }

  @screen xxl {
    background-image: url("../images/bg-texture-1440w.png");
    background-size: 1440px;
  }
}

/* .DynamicBackground {
  @apply fixed inset-0 -z-10;
  @apply w-full h-full;

  transition: background-color 0.6s cubic-bezier(0.06, 0.83, 0.1, 1);

  @nest [data-theme="aqua"] & {
    @apply bg-aqua-light;
  }

  @nest [data-theme="clairvoyance"] & {
    @apply bg-pink;
  }

  @nest [data-theme="variety-pack"] & {
    @apply bg-pink;
  }

  @nest [data-theme="enchantmint"] & {
    @apply bg-theme-enchantmint-bg;
  }

  @nest [data-theme="safari"] & {
    @apply bg-theme-safari-bg;
  }

  @nest [data-theme="walkabout"] & {
    @apply bg-theme-walkabout-bg;
  }

  @nest [data-theme="lavender"] & {
    @apply bg-lavender;
  }
} */

/**
  * When the native `<dialog>` element is not supported, the script toggles the
  * `aria-hidden` attribute on the container. If `aria-hidden` is set to `true`,
  * the container should be hidden entirely.
  */

.Dialog {
  &[aria-hidden="true"] {
    @apply hidden;
  }
}

/**
  * When the native `<dialog>` element is supported, hide `.Modal-overlay`.
  * The dialog::backdrop pseudoelement natively renders in its stead.
  */

.Dialog-overlay {
  @apply fixed z-40 inset-0;
  background-color: rgba(theme("colors.black"), 0.5);

  @nest [data-a11y-dialog-native] > & {
    display: none;
  }
}

.Dialog-content {
  @apply bg-orange-500;
  @apply fixed z-50 inset-0;
  @apply m-0;
}

.Button {
  @apply text-base;
  @apply px-5 py-4 inline-block;

  @apply whitespace-no-wrap;

  @apply bg-beige shadow-md rounded-lg;

  @apply font-sans uppercase font-black;
  @apply leading-none tracking-wider;
  word-spacing: 0.1em;

  transition-property: opacity, box-shadow;
  transition-duration: 150ms;
  transition-timing-function: ease;

  &:hover {
    @apply shadow-lg;
    opacity: 0.85;
  }

  &:focus {
    @apply shadow-outline outline-none;
  }

  @screen md {
    @apply px-6 py-5;
    @apply rounded-lg;
    @apply text-lg;
  }

  @screen lg {
    @apply text-xl;
  }
}

.Button--sm {
  @apply text-sm shadow rounded-lg;
  @apply px-3 py-2;

  &:hover {
    @apply shadow-md;
  }

  @screen md {
    @apply px-4 py-3;
    @apply text-base;
  }
}

.Button--muted {
  background-color: rgba(0, 0, 0, 0.05);
}

.ProductTitle--clairvoyance {
  @screen lg {
    transform: translateX(-15.5%);
  }
}

.ProductTitle--enchantmint {
  @screen lg {
    transform: translateX(14%);
  }
}

.ProductTitle--safari {
  @screen lg {
    transform: translateX(-6.5%);
  }
}

.ProductTitle--walkabout {
  @screen lg {
    transform: translateX(8%);
  }
}

.ProductImage {
  @apply relative;

  @screen lg {
    transform: scale(1.2);
    transform-origin: top;
  }

  &::before {
    @apply absolute inset-0;
    height: 150%;
    width: 150%;
    content: "";
    background-image: radial-gradient(
      closest-side,
      rgba(theme("colors.beige.default"), 0.35) 0%,
      rgba(theme("colors.beige.default"), 0.35) 50%,
      transparent 100%
    );
  }
}

.ProductImage--faintShadow {
  &::before {
    background-image: radial-gradient(
      closest-side,
      rgba(theme("colors.beige.default"), 0.2) 0%,
      rgba(theme("colors.beige.default"), 0.2) 50%,
      transparent 100%
    );
  }
}

.ProductImage--leaningRight {
  &::before {
    transform: translate(-16.666%, -16.666%) rotate(18deg);
  }
}

.ProductImage--leaningLeft {
  &::before {
    transform: translate(-16.666%, -16.666%) rotate(-18deg);
  }
}

.Tab {
  @apply appearance-none;
  @apply uppercase font-bold;
  @apply leading-tight tracking-wider;
  @apply text-2xl;
  @apply pt-3 pb-2 px-2;
  @apply rounded-t;

  &[aria-selected="true"] {
    background-color: rgba(theme("colors.aqua.lighter"), 0.75);
  }

  @screen sm {
    @apply pt-4;
    @apply text-3xl;
  }
}

.TabPanel {
  @apply px-6 pb-12 pt-px;
  @apply shadow-xl;
  @apply rounded-b;
  background-color: rgba(theme("colors.aqua.lighter"), 0.75);

  @screen sm {
    @apply pt-12;
  }
}

.Locator {
  @screen md {
    padding-bottom: 100%;
  }

  @screen lg {
    padding-bottom: 62.5%;
  }

  @screen xl {
    padding-bottom: 50%;
  }
}

.Locator-map {
  padding-bottom: 100%;

  @screen md {
    padding-bottom: 0;
  }
}

.Locator-statusText {
  @apply opacity-0;
  @apply h-0;

  &.is-visible {
    @apply opacity-100;
    @apply px-4 py-6;
    @apply h-auto;
    @apply mb-5;
  }
}

/* Hidden radio-button hack */
/* Element must be preceded by its corresponding radio button */

.Locator-filterOption {
  @apply relative;
  @apply opacity-50;

  @nest input:checked + & {
    @apply opacity-100;
  }

  @nest input:focus + & {
    @apply shadow-outline;
  }

  &.Locator-filterOption:hover {
    @apply opacity-100;
  }
}

.Locator-filterWrapper {
  @apply opacity-0 invisible;
  @apply h-0;

  &.is-visible {
    @apply opacity-100 visible;
    @apply h-auto;
  }
}

.Locator-resultsList {
  @apply -mt-3;
  @apply border-transparent;
  border-top-width: theme("spacing.3");
}

.LocationsGrid {
  @screen sm {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: theme("spacing.6");
    grid-row-gap: theme("spacing.16");
  }

  @screen lg {
    grid-template-columns: repeat(3, 1fr);
  }
}

.PassionFruit {
  width: 30vw;
  margin-top: 13vh;
  transform: rotate(90deg) translate(0, -30%);

  @screen lg {
    width: 24vw;
  }

  @screen xxl {
    width: 360px;
  }
}

.HummingBird {
  width: 24vw;
  margin-top: 59vh;

  @screen lg {
    width: 20vw;
  }

  @screen xxl {
    width: 340px;
  }
}

.Climber {
  top: 73%;
  right: 2%;
  width: 7.5%;

  @screen xl {
    top: 33%;
    right: 5.5%;
    width: 6%;
  }
}

/* Type Styles */

.typeStyleDisplay {
  @apply font-sans font-black;
  @apply leading-tight tracking-tight;
}

.typeStyleUI {
  @apply font-sans uppercase font-black;
  @apply leading-none tracking-wider;
  word-spacing: 0.1em;
}

/* purgecss end ignore */
