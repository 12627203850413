/**
 * This injects Tailwind's base styles, which is a combination of
 * Normalize.css and some additional base styles.
 *
 * You can see the styles here:
 * https://github.com/tailwindcss/tailwindcss/blob/master/css/preflight.css
 *
 * If using `postcss-import`, use this import instead:
 *
 * @import "tailwindcss/preflight";
 */

/* purgecss start ignore */

@tailwind base;

body {
  /* transition: background-color 0.6s cubic-bezier(0.06, 0.83, 0.1, 1); */

  &[data-theme="aqua"] {
    @apply text-aqua-dark;
    @apply bg-aqua-light;
  }

  &[data-theme="clairvoyance"] {
    @apply text-theme-clairvoyance-fg;
    @apply bg-pink;
  }

  &[data-theme="enchantmint"] {
    @apply text-violet;
    @apply bg-theme-enchantmint-bg;
  }

  &[data-theme="safari"] {
    @apply text-green;
    @apply bg-theme-safari-bg;
  }

  &[data-theme="salvation"] {
    @apply text-theme-salvation-fg;
    @apply bg-theme-salvation-bg;
  }

  &[data-theme="walkabout"] {
    @apply text-theme-walkabout-fg;
    @apply bg-theme-walkabout-bg;
  }

  &[data-theme="variety-pack"] {
    @apply text-violet;
    @apply bg-pink;
  }

  &[data-theme="lavender"] {
    @apply text-violet;
    @apply bg-lavender;
  }
}

input {
  &:focus {
    @apply outline-none shadow-outline;
  }

  &[disabled] {
    @apply opacity-50;
  }
}

button {
  &:focus {
    @apply outline-none shadow-outline;
  }

  &[disabled] {
    @apply opacity-50;
  }
}

dialog[open] {
  @apply block;
}

dialog::backdrop {
  background-color: rgba(theme("colors.black"), 0.5);
}

/* purgecss end ignore */
