/* purgecss start ignore */

[data-animate] {
  transition-duration: 1s;
  transition-timing-function: cubic-bezier(0.06, 0.83, 0.1, 1);
}

[data-animate~="400"] {
  transition-duration: 0.4s;
}

[data-animate~="600"] {
  transition-duration: 0.6s;
}

[data-animate~="800"] {
  transition-duration: 0.8s;
}

[data-animate~="1000"] {
  transition-duration: 1s;
}

[data-animate~="ease"] {
  transition-timing-function: ease-in-out;
}

[data-animate~="cubic"] {
  transition-timing-function: cubic-bezier(0.06, 0.83, 0.1, 1);
}

[data-animate~="delay200"] {
  transition-delay: 0.2s;
}

[data-animate~="delay400"] {
  transition-delay: 0.4s;
}

[data-animate~="delay600"] {
  transition-delay: 0.6s;
}

[data-animate~="delay800"] {
  transition-delay: 0.8s;
}

[data-animate~="lg:delay200"] {
  @screen lg {
    transition-delay: 0.2s;
  }
}

[data-animate~="lg:delay400"] {
  @screen lg {
    transition-delay: 0.4s;
  }
}

[data-animate~="lg:delay600"] {
  @screen lg {
    transition-delay: 0.6s;
  }
}

[data-animate~="lg:delay800"] {
  @screen lg {
    transition-delay: 0.8s;
  }
}

[data-animate~="slide-up"] {
  opacity: 0;
  transform: translateY(32px);
  transition-property: opacity, transform;
  /* will-change: opacity, transform; */

  &.is-visible {
    opacity: 1;
    transform: translateY(0);
  }
}

[data-animate~="fade-in"] {
  /* will-change: opacity; */
  opacity: 0;
  transition-property: opacity;

  &.is-visible {
    opacity: 1;
  }
}

[data-animate] {
  transition-duration: 1s;
  transition-timing-function: cubic-bezier(0.06, 0.83, 0.1, 1);
}

/* Keyframe Animations */

@keyframes levitate {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-2%);
  }
}

/* Animations */

.levitate {
  animation: levitate 1.5s ease-in-out infinite alternate;
}

/* purgecss end ignore */
